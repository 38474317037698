export const moduleContentsHeaders = [
  {
    text: 'Count',
    value: 'count',
    hasSlot: true,
    hasHeaderSlot: true,
    sortable: false,
    fixed: true,
    align: 'center',
  },
  {
    text: 'Module type',
    value: 'module_type',
    fixed: true,
    align: 'center',
    width: 150,
    hasSlot: true,
  },
  {
    text: 'Cage',
    value: 'cage',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_1.1',
    value: 'hsb_p1',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_1.2',
    value: 'hsb_p2',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_1.3',
    value: 'hsb_p3',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_2.1',
    value: 'hsb_p4',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_2.2',
    value: 'hsb_p5',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_2.3',
    value: 'hsb_p6',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_3.1',
    value: 'hsb_p7',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_3.2',
    value: 'hsb_p8',
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    hasSlot: true,
    sortable: false,
  },
  {
    text: 'HSB_3.3',
    value: 'hsb_p9',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
    align: 'center',
  },
  {
    text: 'HSB_3.4',
    value: 'hsb_p10',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_3.5',
    value: 'hsb_p11',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_3.6',
    value: 'hsb_p12',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_4.1',
    value: 'hsb_p13',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_4.2',
    value: 'hsb_p14',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_4.3',
    value: 'hsb_p15',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_4.4',
    value: 'hsb_p16',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_4.5',
    value: 'hsb_p17',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_4.6',
    value: 'hsb_p18',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_5.1',
    value: 'hsb_p19',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_5.2',
    value: 'hsb_p20',
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    hasSlot: true,
    sortable: false,
  },
  {
    text: 'HSB_5.3',
    value: 'hsb_p21',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
    align: 'center',
  },
  {
    text: 'HSB_5.4',
    value: 'hsb_p22',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_5.5',
    value: 'hsb_p23',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_5.6',
    value: 'hsb_p24',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_5.7',
    value: 'hsb_p25',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_5.8',
    value: 'hsb_p26',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_5.9',
    value: 'hsb_p27',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_5.10',
    value: 'hsb_p28',
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    hasSlot: true,
    sortable: false,
  },
  {
    text: 'HSB_PL.1',
    value: 'hsb_pl_p1',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HSB_PL.2',
    value: 'hsb_pl_p2',
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    hasSlot: true,
    sortable: false,
  },
  {
    text: 'S_KPL.1',
    value: 's_kpl_p1',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S_KPL.2',
    value: 's_kpl_p2',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S_POR.1',
    value: 's_por_p1',
    hasSlot: true,
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
    align: 'center',
  },
  {
    text: 'S_POR.2',
    value: 's_por_p2',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S_POR.3',
    value: 's_por_p3',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S_POR.4',
    value: 's_por_p4',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S.DKL.1.1',
    value: 's_dkl_p1',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S.DKL.1.2',
    value: 's_dkl_p2',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    align: 'center',
    sortable: false,
  },
  {
    text: 'S.DKL.2.1',
    value: 's_dkl_p3',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S.DKL.2.2',
    value: 's_dkl_p4',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    align: 'center',
    sortable: false,
  },
  {
    text: 'S.DKL.3.1',
    value: 's_dkl_p5',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S.DKL.3.2',
    value: 's_dkl_p6',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    align: 'center',
    sortable: false,
  },
  {
    text: 'S.DKL.4.1',
    value: 's_dkl_p7',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S.DKL.4.2',
    value: 's_dkl_p8',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S.WVB.1',
    value: 's_wvb_p1',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S.WVB.2',
    value: 's_wvb_p2',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    align: 'center',
    sortable: false,
  },
  {
    text: 'S.WVB.3',
    value: 's_wvb_p3',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'S.WVB.4',
    value: 's_wvb_p4',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'VLOER',
    value: 'vloer',
    hasSlot: true,
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
    align: 'center',
  },

  {
    text: 'G1.KOZ_1.1',
    value: 'g1_koz_p11',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G1.KOZ_1.2',
    value: 'g1_koz_p12',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G1.KOZ_2.1',
    value: 'g1_koz_p21',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G1.KOZ_2.2',
    value: 'g1_koz_p22',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G1.KOZ_3.1',
    value: 'g1_koz_p31',
    hasSlot: true,
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    align: 'center',
    sortable: false,
  },
  {
    text: 'G1.KOZ_3.2',
    value: 'g1_koz_p32',
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    hasSlot: true,
    sortable: false,
  },
  {
    text: 'G1.KOZ_3.3',
    value: 'g1_koz_p33',
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    hasSlot: true,
    sortable: false,
  },
  {
    text: 'G1.KOZ_4.1',
    value: 'g1_koz_p41',
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    hasSlot: true,
    sortable: false,
  },
  {
    text: 'G1.KOZ_4.2',
    value: 'g1_koz_p42',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G1.KOZ_4.3',
    value: 'g1_koz_p43',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_1.1',
    value: 'g2_koz_p11',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_1.2',
    value: 'g2_koz_p12',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_2.1',
    value: 'g2_koz_p21',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_2.2',
    value: 'g2_koz_p22',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_3.1',
    value: 'g2_koz_p31',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_3.2',
    value: 'g2_koz_p32',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_3.3',
    value: 'g2_koz_p33',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_4.1',
    value: 'g2_koz_p41',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_4.2',
    value: 'g2_koz_p42',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G2.KOZ_4.3',
    value: 'g2_koz_p43',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_1.1',
    value: 'g3_koz_p11',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_1.2',
    value: 'g3_koz_p12',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_2.1',
    value: 'g3_koz_p21',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_2.2',
    value: 'g3_koz_p22',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_3.1',
    value: 'g3_koz_p31',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_3.2',
    value: 'g3_koz_p32',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_3.3',
    value: 'g3_koz_p33',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_4.1',
    value: 'g3_koz_p41',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_4.2',
    value: 'g3_koz_p42',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'G3.KOZ_4.3',
    value: 'g3_koz_p43',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_3.1',
    value: 'b_koz_p31',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_3.2',
    value: 'b_koz_p32',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_4.1',
    value: 'b_koz_p41',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_4.2',
    value: 'b_koz_p42',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_5.1',
    value: 'b_koz_p51',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-dark',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_5.2',
    value: 'b_koz_p52',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_5.3',
    value: 'b_koz_p53',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_5.4',
    value: 'b_koz_p54',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_5.5',
    value: 'b_koz_p55',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_5.6',
    value: 'b_koz_p56',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },

  {
    text: 'B.KOZ_5.7',
    value: 'b_koz_p57',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_5.8',
    value: 'b_koz_p58',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_5.9',
    value: 'b_koz_p59',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'B.KOZ_5.10',
    value: 'b_koz_p510',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'BDK',
    value: 'bdk_p1',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'KKNB',
    value: 'kkn_p1',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'KACH.1',
    value: 'kach_p1',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'KACH.2',
    value: 'kach_p2',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'ISO',
    value: 'iso_p1',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'BMW',
    value: 'bwm_p1',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'HOUT',
    value: 'hout_p1',
    hasSlot: true,
    align: 'center',
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
  {
    text: 'VENST',
    value: 'venst_p1',
    align: 'center',
    hasSlot: true,
    class: 'ant-border-left-darker',
    input: true,
    hasHeaderSlot: true,
    shown: true,
    sortable: false,
  },
];

export const moduleHeaders = [
  {
    text: 'Build number',
    value: 'build_nr',
    hasSlot: true,
    input: true,
    type: 'text',
    align: 'center',
    required: true,
  },
  {
    text: 'Module type',
    value: 'module_type',
    hasSlot: true,
    input: true,
    type: 'text',
    align: 'center',
    required: true,
  },
  {
    text: 'Placement sequence',
    value: 'placement_sequence',
    hasSlot: true,
    input: true,
    type: 'number',
    width: '150px',
    align: 'center',
  },

  {
    text: 'House number',
    value: 'house_nr',
    hasSlot: true,
    input: true,
    type: 'number',
    width: '150px',
    align: 'center',
  },
  {
    text: 'Module ID (Equipment nr)',
    value: 'module_id',
    hasSlot: true,
    input: true,
    type: 'text',
    align: 'center',
    required: true,
  },
  {
    text: 'Assembly sequence',
    value: 'assembly_sequence',
    hasSlot: true,
    input: true,
    type: 'number',
    width: '150px',
    align: 'center',
    required: true,
  },
  {
    text: 'Workload',
    value: 'assembly_workload',
    hasSlot: true,
    input: true,
    type: 'number',
    width: '150px',
    align: 'center',
    required: true,
  },
  {
    text: 'Phase',
    value: 'phase',
    hasSlot: true,
    sortable: false,
    input: true,
    align: 'center',
  },
  {
    text: '2D PDF',
    value: '2d_drawing',
    hasSlot: true,
    sortable: false,
    input: true,
    align: 'center',
  },
  { text: 'Actions', value: 'actions', align: 'right', sortable: false },
];
